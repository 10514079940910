import {mapMutations, mapActions, mapGetters} from "vuex";

export default {
  name: "server-not-found",
  components: {},
  data() {
    return {}
  },
  mounted() {
    document.title = `${this.$t('error.serverError')} | karbosnab`
  },
  created() {

  },
  computed: {},
  methods: {
    goHomePage() {
      this.$router.push({name: 'home'})
    }
  }
}
